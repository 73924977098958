import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { Wizard, useWizardState } from '@shared/ui/misc/Wizard';
import Sidebar from '@shared/ui/sidebars/Sidebar';
import PaymentMethod from './ui/steps/PaymentMethod';
import EnterAmount from './ui/steps/EnterAmount';
import ChooseNetwork from './ui/steps/ChooseNetwork';
import ConvertAssets from './ui/steps/ConvertAssets';
import CardDetails from './ui/steps/CardDetails';
import CalculationProcess from './ui/steps/CalculationProcess';
import Success from './ui/steps/Success';
import Error from './ui/steps/Error';
const BalanceManagementSidebarContent = ({ exchangeWallet, onClose, }) => {
    const wizardState = useWizardState(8);
    // NOTE: can be used in steps in order to submit this data
    const [cardDetails, setCardDetails] = useState();
    const [amount, setAmount] = useState();
    const [convertAssetsData, setConvertAssetsData] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const closeSidebar = () => {
        onClose();
    };
    return (_jsxs(Wizard, { useExternalState: wizardState, children: [_jsx(PaymentMethod, { exchangeWallet: exchangeWallet, onPaymentMethodFormSubmit: setPaymentMethod, onSidebarClose: closeSidebar }), _jsx(EnterAmount, { onAmountFormSubmit: setAmount, onSidebarClose: closeSidebar }), _jsx(ChooseNetwork, {}), _jsx(ConvertAssets, { exchangeWallet: exchangeWallet, onConvertAssetsFormSubmit: setConvertAssetsData, onSidebarClose: closeSidebar }), _jsx(CardDetails, { onCardDetailsFormSubmit: setCardDetails, onSidebarClose: closeSidebar }), _jsx(CalculationProcess, {}), _jsx(Success, { amount: (amount === null || amount === void 0 ? void 0 : amount.amount) || 0, onSidebarClose: closeSidebar }), _jsx(Error, {})] }));
};
// NOTE: wrapping in sidebar here,
// so that Content will be mounted only when sidebar is open
const BalanceManagementSidebar = ({ exchangeWallet, onClose, isOpen = false, }) => (_jsx(Sidebar, { isOpen: isOpen, children: _jsx(BalanceManagementSidebarContent, { exchangeWallet: exchangeWallet, onClose: onClose }) }));
export default BalanceManagementSidebar;
