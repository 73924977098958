export var AddBalanceMethod;
(function (AddBalanceMethod) {
    AddBalanceMethod["AddByCrypto"] = "ADD_BY_CRYPTO";
    AddBalanceMethod["AddByCard"] = "ADD_BY_CARD";
    AddBalanceMethod["ConvertAssets"] = "CONVERT_ASSETS";
    AddBalanceMethod["None"] = "";
})(AddBalanceMethod || (AddBalanceMethod = {}));
export var BalanceManagementSteps;
(function (BalanceManagementSteps) {
    BalanceManagementSteps[BalanceManagementSteps["ChoosePaymentMethod"] = 0] = "ChoosePaymentMethod";
    BalanceManagementSteps[BalanceManagementSteps["EnterAmount"] = 1] = "EnterAmount";
    BalanceManagementSteps[BalanceManagementSteps["ChooseNetwork"] = 2] = "ChooseNetwork";
    BalanceManagementSteps[BalanceManagementSteps["ConvertAssets"] = 3] = "ConvertAssets";
    BalanceManagementSteps[BalanceManagementSteps["CardDetails"] = 4] = "CardDetails";
    BalanceManagementSteps[BalanceManagementSteps["CalculationProcess"] = 5] = "CalculationProcess";
    BalanceManagementSteps[BalanceManagementSteps["Success"] = 6] = "Success";
    BalanceManagementSteps[BalanceManagementSteps["Error"] = 7] = "Error";
})(BalanceManagementSteps || (BalanceManagementSteps = {}));
