import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import LoadingIndicator from '@shared/ui/progress-loaders/LoadingIndicator';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { ContainerRow } from '@components/styled';
import { StepContainer, Title } from '@widgets/sidebars/shared';
import { useWizard } from '@shared/ui/misc/Wizard';
import { BalanceManagementSteps } from '../../../types';
const CalculationProcessContainer = styled(ContainerRow)();
const IndicatorContainer = styled.div(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 5,
}));
const CalculationProcess = () => {
    const { t } = useTranslation();
    const { goTo } = useWizard();
    // temporary decision just for loader animation
    useEffect(() => {
        setTimeout(() => {
            goTo(BalanceManagementSteps.Success);
        }, 2000);
    }, [goTo]);
    return (_jsx(SidebarInner, { padding: '50px 30px', children: _jsx(CalculationProcessContainer, { children: _jsx(StepContainer, { content: (_jsxs(_Fragment, { children: [_jsx(IndicatorContainer, { children: _jsx(LoadingIndicator, { isLoading: true }) }), _jsx(Title, { children: t('overview.balance_management.calculation_process.text') })] })) }) }) }));
};
export default CalculationProcess;
